import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-524fae24"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review-whisper-card" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "review-flex-container" }
const _hoisted_4 = { class: "review-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "user-profile-photo",
      src: $props.review.user.profile_photo,
      alt: "User's Profile Photo"
    }, null, 8, _hoisted_2),
    _createElementVNode("h3", null, _toDisplayString($props.review.user.name), 1),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", null, _toDisplayString($props.review.review_text), 1),
        _createElementVNode("span", null, "Valutazione: " + _toDisplayString($props.review.rating) + " / 5", 1)
      ])
    ])
  ]))
}
<template>
    <div class="swiper-container">
        <!-- background swiper -->
        <swiper h:style="{
            '--swiper-navigation-color': '#fff',
            '--swiper-pagination-color': '#fff',
        }" :speed="600" :parallax="true" :pagination="{
            clickable: true,
        }" :navigation="true" :modules="modules" class="mySwiper" :slidesPerView="'auto'" @slideChange="onSlideChange"
            @swiper="setFirstSwiper" :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }">
            <div slot="container-start" class="parallax-bg" :style="{
                'background-image': `url(${currentBackgroundImage})`,
            }" data-swiper-parallax="-23%"></div>
            <swiper-slide v-for="(product, index) in products" :key="product.id">
                <div class="title" data-swiper-parallax="-300">{{ product.name }}</div>
                <div class="text" data-swiper-parallax="-100">
                    <p>{{ product.description }}</p>
                </div>
                <div class="button">
                    <div class="button">
                        <button @click="goToProduct(product.products[0].ID)"
                            class="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none text-xs py-3 px-6 rounded-lg bg-blue-500 text-white shadow-md shadow-blue-500/10 hover:shadow-lg hover:shadow-blue-500/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                            type="button">
                            Più informazioni...
                        </button>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
        <!-- coverflow swiper -->
        <swiper @swiper="setSecondSwiper" @slideChange="onSlideChangeSecond" :effect="'coverflow'" :grabCursor="true"
            :centeredSlides="true" :slidesPerView="'auto'" :autoplay="{
                delay: 2500,
                disableOnInteraction: false,
            }" :coverflowEffect="{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }" :pagination="false" :modules="modules" class="mySwiperDue" :breakpoints="{
                320: {
                    slidesPerView: 0,
                    spaceBetween: 10,
                    coverflowEffect: {
                        rotate: 30,
                        stretch: 0,
                        depth: 50,
                        modifier: 1,
                        slideShadows: false,
                    },
                },
                480: {
                    slidesPerView: 0,
                    spaceBetween: 20,
                    coverflowEffect: {
                        rotate: 30,
                        stretch: 0,
                        depth: 60,
                        modifier: 1,
                        slideShadows: false,
                    },
                },
                640: {
                    slidesPerView: 'auto',
                    coverflowEffect: {
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: true,
                    },
                }
            }">
            <!-- swiper-slide elements -->
            <swiper-slide v-for="(product, index) in products" :key="product.id">
                <div class="card" :style="`background-image: url(${product.image_url});`">
                    <div class="card-content">
                        <h3 class="card-title">{{ product.name }}</h3>
                    </div>
                </div>
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Parallax, Pagination, Navigation, EffectCoverflow, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/effect-coverflow';


export default {
    name: 'AppSliderService',
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        //syncronize the two swipers
        const firstSwiper = ref(null);
        const secondSwiper = ref(null);

        const setFirstSwiper = (swiper) => {
            firstSwiper.value = swiper;
        };
        const setSecondSwiper = (swiper) => {
            secondSwiper.value = swiper;
        };

        const onSlideChange = (swiper) => {
            if (secondSwiper.value) {
                secondSwiper.value.slideTo(swiper.activeIndex);
            }
        }
        const onSlideChangeSecond = (swiper) => {
            if (firstSwiper.value) {
                firstSwiper.value.slideTo(swiper.activeIndex);
            }
        }

        return {
            setFirstSwiper,
            setSecondSwiper,
            onSlideChange,
            onSlideChangeSecond,
        };
    },
    data() {
        return {
            currentBackgroundImage: 'https://www.afterfiveliving.it/wp-content/uploads/2023/11/AdobeStock_671971642-1.jpg',
            modules: [Parallax, Pagination, Navigation, EffectCoverflow, Autoplay],
            products: [],
        };
    },
    mounted() {
        this.fetchProducts();
    },
    methods: {

        goToProduct(id) {
            this.$router.push(`/servizi/${id}`);
        },

        async fetchProducts() {
            try {
                const baseUrl = process.env.VUE_APP_API_BASE_URL;
                const response = await fetch(`${baseUrl}/categories`);
                if (!response.ok) {
                    throw new Error('Failed to fetch products');
                }
                this.products = await response.json();

                console.log(this.products);
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        },
    },
};
</script>

<style scoped>
/* disable the second swiper on mobile and tablet 
@media (max-width: 1279px) {
    .mySwiperDue {
        display: none;
    
}*/

/* Mobile and default styles */
@media screen and (max-width: 1579px) {
    .mySwiperDue {
        display: none;
    }

}

.mySwiperDue {
    position: absolute;
    top: 190px;
    left: 790px;
    /* Assicura che il secondo swiper sia sopra il primo */
    z-index: 1;
    width: 50%;
}

.mySwiperDue .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
}

.parallax-bg {
    transition: background-image 0.5s ease-in-out;
    /* Adjust the duration and easing as needed */
    position: absolute;
    left: 0;
    top: 0;
    width: 130%;
    height: 100%;
    -webkit-background-size: cover;
    background-size: cover;
    background-position: center;
}

.white-arial-text {
    color: white;
    font-family: Arial, sans-serif;
}

/*  Background swiper */
.mySwiper {
    width: 100%;
    height: 700px;
    background: #000;
}

.mySwiper .swiper-slide {
    font-size: 18px;
    color: #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 40px 60px;
}

.mySwiper .swiper-slide .title {
    font-size: 41px;
    font-weight: 300;
    color: rgb(59 130 246);
}

.mySwiper .swiper-slide .subtitle {
    font-size: 21px;
}

.mySwiper .swiper-slide .text {
    font-size: 20px;
    max-width: 400px;
    line-height: 1.3;
}

.mySwiper .swiper-slide .button {
    padding: 10px 0;
}

.swiper-container {
    position: relative;
}

/* Coverflow card swiper */
.card {
    position: relative;
    width: 100%;
    height: 300px;
    /* Adjust based on your needs */
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    /* Optional: for rounded corners */
    overflow: hidden;
    /* Keeps everything within the rounded corners */
}

.card-content {
    position: absolute;
    bottom: 20px;
    /* Adjust based on your needs */
    left: 20px;
    /* Adjust based on your needs */
    color: white;
    /* Ensures text is visible on darker backgrounds */
}

.card-title {
    margin: 0;
    font-size: 24px;
    /* Adjust based on your needs */
}

.card-subtitle {
    margin: 0;
    font-size: 16px;
    /* Adjust based on your needs */
}
</style>
import './assets/main.css'

import { createApp } from 'vue'
import Notifications from 'notiwind'
import App from './App.vue'
import router from './router'
//import VueCookieAcceptDecline from 'vue-cookie-accept-decline';
//import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css';


//pagedone import

const app = createApp(App)

app.use(Notifications)
app.use(router)

import './icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome' // configuring fontawesome icons
app.component('font-awesome-icon', FontAwesomeIcon) // creating fontawesome component
//app.component('vue-cookie-accept-decline', VueCookieAcceptDecline); // creating cookie component

app.mount('#app')
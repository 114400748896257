<template>
    <div ref="statistiche">
        <div class="pt-12 bg-gray-50 dark:bg-gray-900 sm:pt-20">
            <div class="max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
                <div class="max-w-4xl mx-auto text-center">
                    <h2
                        class="text-3xl font-extrabold leading-9 text-gray-900 dark:text-white sm:text-4xl sm:leading-10">
                        Pucci & Figli è sinonimo di qualità e affidabilità
                    </h2>
                    <p class="mt-3 text-xl leading-7 text-gray-600 dark:text-gray-400 sm:mt-4">
                        Con oltre 25 anni di esperienza, abbiamo effetuato più di 15.000 lavori e soddisfatto oltre
                        10.000 clienti. La nostra dedizione e competenza ci hanno permesso di diventare un punto di
                        riferimento nella regione umbra, offrendo soluzioni su misura per la protezione e l'abbellimento
                        degli spazi abitativi ed esterni.
                    </p>
                </div>
            </div>
            <div class="pb-12 mt-10 bg-gray-50 dark:bg-gray-900 sm:pb-16">
                <div class="relative">
                    <div class="absolute inset-0 h-1/2 bg-gray-50 dark:bg-gray-900"></div>
                    <div class="relative max-w-screen-xl px-4 mx-auto sm:px-6 lg:px-8">
                        <div class="max-w-4xl mx-auto">
                            <dl class="bg-white dark:bg-gray-800 rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                                <div
                                    class="flex flex-col p-6 text-center border-b border-gray-100 dark:border-gray-700 sm:border-0 sm:border-r">
                                    <dt
                                        class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">
                                        LAVORI EFFETTUATI
                                    </dt>
                                    <dd
                                        class="order-1 text-5xl font-extrabold leading-none text-indigo-600 dark:text-indigo-100">
                                        <span class="emoji">🔧</span>
                                        {{ persianeRestaurate }}<span class="small-plus">+</span>
                                    </dd>
                                </div>
                                <div
                                    class="flex flex-col p-6 text-center border-t border-b border-gray-100 dark:border-gray-700 sm:border-0 sm:border-l sm:border-r">
                                    <dt
                                        class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">
                                        CLIENTI SODDISFATTI
                                    </dt>
                                    <dd
                                        class="order-1 text-5xl font-extrabold leading-none text-indigo-600 dark:text-indigo-100">
                                        <span class="emoji">😊</span>

                                        {{ clientiSoddisfatti }}<span class="small-plus">+</span>
                                    </dd>
                                </div>
                                <div
                                    class="flex flex-col p-6 text-center border-t border-gray-100 dark:border-gray-700 sm:border-0 sm:border-l">
                                    <dt
                                        class="order-2 mt-2 text-lg font-medium leading-6 text-gray-500 dark:text-gray-400">
                                        ANNI DI ESPERIENZA
                                    </dt>
                                    <dd
                                        class="order-1 text-5xl font-extrabold leading-none text-indigo-600 dark:text-indigo-100">
                                        <span class="emoji">🏆</span>
                                        {{ anniEsperienza }}<span class="small-plus">+</span>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            targets: [
                { key: 'persianeRestaurate', count: 15683 },
                { key: 'clientiSoddisfatti', count: 12432 },
                { key: 'anniEsperienza', count: 25 },
            ],
            persianeRestaurate: 0,
            clientiSoddisfatti: 0,
            anniEsperienza: 0,
        };
    },
    mounted() {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    this.startAnimation();
                    observer.unobserve(this.$refs.statistiche);
                }
            });
        }, {
            threshold: 0.1
        });

        observer.observe(this.$refs.statistiche);
    },
    methods: {
        startAnimation() {
            const maxDuration = 2000; // total duration for the animation in milliseconds
            this.targets.forEach(target => {
                this.animateCountUp(target.key, target.count, maxDuration);
            });
        },
        animateCountUp(key, targetCount, duration) {
            let currentCount = 0;
            const increment = targetCount / (duration / 10);

            const interval = setInterval(() => {
                currentCount += increment;
                if (currentCount >= targetCount) {
                    clearInterval(interval);
                    currentCount = targetCount;
                }
                this[key] = Math.ceil(currentCount);
            }, 10);
        },
    },
};
</script>

<style scoped>
.small-plus {
    font-size: 0.45em;
    /* Adjust the font size as needed */
}

.emoji {
    display: block;
    font-size: 3rem;
    /* Adjust the size of the emoji as needed */
    margin-bottom: 0.5rem;
    /* Adjust the spacing between the emoji and the number */
}
</style>
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'


const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/chi-siamo',
    name: 'chi-siamo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contattaci',
    name: 'contattaci',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/servizi',
    name: 'servizi',
    component: () => import(/* webpackChunkName: "services" */ '../views/ServicesView.vue')
  },
  // all other routes redirect to home
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'home' }
  },
  //service list /servizi/:id
  {
    path: '/servizi/:id',
    name: 'servizi-id',
    component: () => import(/* webpackChunkName: "service" */ '../views/ServiziFullDesctiptionPage.vue')
  },
  {
    path: '/extra-servizi/:id',
    name: 'extra-servizi-id',
    component: () => import(/* webpackChunkName: "service" */ '../views/SubCategoryView.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router

<template>
    <div class="review-whisper-card">
        <img class="user-profile-photo" :src="review.user.profile_photo" alt="User's Profile Photo">
        <h3>{{ review.user.name }}</h3>

        <div class="review-flex-container">
            <div class="review-content">
                <p>{{ review.review_text }}</p>
                <span>Valutazione: {{ review.rating }} / 5</span>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Review } from '@/types/review';
export default {
    name: 'ReviewWhisperCard',
    props: {
        review: {
            type: Object as () => Review,
            required: true,
        },
    },
};
</script>


<style scoped>
.user-profile-photo {
    width: 50px;
    height: 50px;
    /*border-radius: 10%;  è gia rotona lol */
    object-fit: cover;
    /*Assicura che l'immagine copra l'area senza distorcere*/
    margin-bottom: 10px;
    /* Adds some space between the photo and the text */
}

.review-whisper-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* Centers the content horizontally */
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    height: 350px;
}


.review-content h3 {
    margin: 0;
    color: #333;
    font-size: 18px;
}

.review-flex-container {
    width: 100%;
    /* Ensures the container takes up the full width */

    display: flex;
    align-items: center;
    /* Centers the items vertically */
}

.review-content {
    text-align: center;
    /* Centers the text content */
}

.review-content p {
    color: #666;
    font-size: 14px;
    margin-top: 10px;
}

.review-content span {
    display: block;
    margin-top: 10px;
    font-size: 12px;
    color: #333;
}
</style>
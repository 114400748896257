<template>
    <div class="swiper-review">
        <swiper :autoplay="{
            delay: 2500,
            disableOnInteraction: false,
        }" :slidesPerView="1" :spaceBetween="20" :breakpoints="{
            '640': {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            '768': {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            '1024': {
                slidesPerView: 5,
                spaceBetween: 50,
            },
        }" :modules="modules" class="SwiperGoogleReview">
         :product="productItem"
            <swiper-slide v-for="(reviewItem, index) in reviews" :key="index">
                <review-whisper-card :review="reviewItem" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Pagination, Autoplay } from 'swiper/modules';
import axios from 'axios';
import ReviewWhisperCard from '@/components/cards/ReviewWhisperCard.vue';
import 'swiper/css';
import 'swiper/css/pagination';

const showLanding = ref(true)
const reviews = ref([]);

const fetchProducts = async () => {
    try {
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseUrl}/reviews`);
        reviews.value = response.data;
    } catch (error) {
        console.error('Error in get reviews from backend:', error);
    }
};

export default defineComponent({
    name: 'GoogleReviews',
    components: {
        ReviewWhisperCard,
        Swiper,
        SwiperSlide


    },
    setup() {
        const showLanding = ref(true);

        return {
            modules: [Pagination, Autoplay],
            showLanding,
            reviews
        }
    },
    mounted() {
        fetchProducts();
    }    
});
</script>
<style scoped>
.swiper-review {
    width: 100%;
    height: 100%;
    padding: 40px;
    background-color: #fff;
}

.SwiperGoogleReview {
    width: 100%;
    height: 100%;
}

.swiper-review .swiper {
    width: 100%;
    height: 100%;
}

.swiper-review .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-review .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-748d37e8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "swiper-review" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_review_whisper_card = _resolveComponent("review-whisper-card")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_swiper, {
      autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
      slidesPerView: 1,
      spaceBetween: 20,
      breakpoints: {
            '640': {
                slidesPerView: 2,
                spaceBetween: 20,
            },
            '768': {
                slidesPerView: 4,
                spaceBetween: 40,
            },
            '1024': {
                slidesPerView: 5,
                spaceBetween: 50,
            },
        },
      modules: _ctx.modules,
      class: "SwiperGoogleReview"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" :product=\"productItem\" "),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.reviews, (reviewItem, index) => {
          return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
            default: _withCtx(() => [
              _createVNode(_component_review_whisper_card, { review: reviewItem }, null, 8, ["review"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modules"])
  ]))
}
<template>
    <nav class="block w-full sticky top-0 z-10 transition-all duration-300"
        :class="{ '-translate-y-full absolute': !showNavbar }" id="navbar">
        <!-- Navbar Body -->
        <div class="flex flex-wrap items-center justify-between px-9 md:py-4 bg-white/[.9] dark:bg-slate-900 backdrop-blur-sm transition-shadow duration-300"
            :class="{ 'shadow-lg dark:bg-slate-900/[.8]': !hideNavShadow }">
            <!-- Logo -->
            <router-link to="/">
                <img src="@/assets/logo.png" class="w-24 md:w-24 lg:w-20 h-auto" id="logo-img" />
            </router-link>

            <!-- Mobile navbar icon  -->
            <button @click="dropDownActive = !dropDownActive"
                :class="['inline-flex items-center p-2 ml-3 text-sm text-gray-500 xl:hidden transition-all motion-reduce:transition-none duration-500', showTransition ? 'opacity-1 blur-0' : 'opacity-0 blur-sm']">
                <div v-if="!dropDownActive">
                    <Bars2Icon class="h-10 w-10 dark:fill-slate-300" />
                </div>
                <div v-else>
                    <XMarkIcon class="h-10 w-10 dark:fill-slate-300" />
                </div>
            </button>

            <div class="hidden w-full xl:block xl:w-auto items-center" id="navbar-default">
                <ul class="font-medium flex flex-col p-4 xl:p-0 mt-4 xl:flex-row xl:space-x-8 xl:mt-0">
                    <li
                        :class="['transition-all motion-reduce:transition-none duration-500 delay-[50ms]', showTransition ? 'translate-y-0 opacity-1' : '-translate-y-4 opacity-0']">
                        <router-link class="block py-2 pl-3 pr-4 hover:text-link-color" to="/">HOME</router-link>
                    </li>
                    <li
                        :class="['transition-all motion-reduce:transition-none duration-500 delay-[100ms]', showTransition ? 'translate-y-0 opacity-1' : '-translate-y-4 opacity-0']">
                        <router-link class="block py-2 pl-3 pr-4 hover:text-link-color"
                            to="/servizi">SERVIZI</router-link>
                    </li>
                    <li
                        :class="['transition-all motion-reduce:transition-none duration-500 delay-[150ms]', showTransition ? 'translate-y-0 opacity-1' : '-translate-y-4 opacity-0']">
                        <router-link class="block py-2 pl-3 pr-4 hover:text-link-color" to="/chi-siamo">CHI
                            SIAMO</router-link>
                    </li>
                    <li
                        :class="['transition-all motion-reduce:transition-none duration-500 delay-[250ms]', showTransition ? 'translate-y-0 opacity-1' : '-translate-y-4 opacity-0']">
                        <router-link class="block py-2 pl-3 pr-4 hover:text-link-color" to="/contattaci">📭
                            RICHIEDI UN PREVENTIVO ORA</router-link>

                    </li>

                </ul>
            </div>
        </div>

        <!-- Navbar Dropdown Area (For mobile device )-->
        <NavTransition>
            <div v-show="dropDownActive"
                class="block w-full absolute xl:hidden px-9 pb-4 bg-white/[.9] dark:bg-slate-900/[.8] backdrop-blur-sm">
                <ul>
                    <li>
                        <router-link class="block py-5  hover:text-link-color" to="/">HOME</router-link>
                    </li>
                    <li>
                        <router-link class="block py-5  hover:text-link-color" to="/servizi">SERVIZI</router-link>
                    </li>
                    <li>
                        <router-link class="block py-5  hover:text-link-color" to="/chi-siamo">CHI SIAMO</router-link>
                    </li>
                    <li>
                        <router-link class="block py-5  hover:text-link-color" to="/contattaci">CONTATTACI</router-link>
                    </li>
                </ul>
            </div>
        </NavTransition>
    </nav>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import NavTransition from './transition/NavTransition.vue';
import { Bars2Icon, XMarkIcon } from '@heroicons/vue/24/solid';

export default defineComponent({
    components: {
        NavTransition,
        Bars2Icon,
        XMarkIcon
    },
    props: {
        showTransition: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        let dropDownActive = ref(false);
        let showNavbar = ref(true);
        let prevScrollPosition = ref(0);
        let hideNavShadow = ref(true);

        onMounted(() => {
            window.addEventListener('scroll', onScroll);
        });

        onBeforeUnmount(() => {
            window.removeEventListener('scroll', onScroll);
        });

        // superato il 60% della pagina, nascondo la navbar   
        let onScroll = () => {
            const currScrollPosition = window.scrollY

            if (currScrollPosition <= 60) {
                hideNavShadow.value = true
            } else {
                hideNavShadow.value = false
            }

            if (currScrollPosition < 0 || Math.abs(currScrollPosition - prevScrollPosition.value) < 60) {
                return
            }

            showNavbar.value = currScrollPosition < prevScrollPosition.value
            prevScrollPosition.value = currScrollPosition
            dropDownActive.value = false
        }

        return { dropDownActive, showNavbar, prevScrollPosition, hideNavShadow };
    }
});
</script>